import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface CaseChartProps {
  data?: { cases: number; date: string }[];
}

export function CaseChart({ data }: CaseChartProps) {
  return (
    <div className="flex justify-center mt-8">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={1000}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" stroke="#888888" fontSize={12} />
          <YAxis
            stroke="#888888"
            fontSize={12}
            tickLine={false}
            tickFormatter={(value) => `${value}`}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Line type="monotone" dataKey="cases" stroke="#83C3E1" activeDot={{ r: 8 }} />
          <Tooltip />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
