import { useEffect, useState } from 'react';

import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addDays,
  addWeeks,
  addMonths,
  subDays,
  subWeeks,
  subMonths,
  isWithinInterval,
  isSameDay,
  setDefaultOptions,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import clsx from 'clsx';
import { DateRange } from 'react-day-picker';
import { enUS, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { generateMonthOptions, generateYearOptions } from '@/utils/dates';
import { get_language_setting } from '@/utils/translations';

import { RadioGroupOption } from './form/radio-group-opt';
import { RadioGroup } from './form/radio-group';
import { SelectField } from './form/select-field';
import { Calendar } from './calendar';

type SelectorType = 'day' | 'week' | 'month' | 'custom';

interface TimeRangeSelectorProps {
  value: SelectorType;
  onChange: (value: SelectorType) => void;
  onDateChange: (start: Date, end: Date) => void;
}

const timeRangeConfig = {
  day: {
    getRange: (date: Date) => ({ start: startOfDay(date), end: endOfDay(date) }),
    format: (date: Date) => format(date, 'PPP'),
    move: (date: Date, direction: 'forward' | 'backward') =>
      direction === 'forward' ? addDays(date, 1) : subDays(date, 1),
  },
  week: {
    getRange: (date: Date) => ({ start: startOfWeek(date), end: endOfWeek(date) }),
    format: (date: Date) =>
      `${format(startOfWeek(date), 'MMM d, yyyy')} - ${format(endOfWeek(date), 'MMM d, yyyy')}`,
    move: (date: Date, direction: 'forward' | 'backward') =>
      direction === 'forward' ? addWeeks(date, 1) : subWeeks(date, 1),
  },
  month: {
    getRange: (date: Date) => ({ start: startOfMonth(date), end: endOfMonth(date) }),
    format: (date: Date) => format(date, 'MMMM yyyy'),
    move: (date: Date, direction: 'forward' | 'backward') =>
      direction === 'forward' ? addMonths(date, 1) : subMonths(date, 1),
  },
  custom: {
    getRange: (date: Date, endDate: Date) => ({ start: date, end: endDate }),
    format: (date: Date, endDate: Date) =>
      `${format(date, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`,
    move: (date: Date, endDate: Date, direction: 'forward' | 'backward') => {
      const diff = endDate.getTime() - date.getTime();
      const newStartDate = direction === 'forward' ? addDays(date, 1) : subDays(date, 1);
      return { start: newStartDate, end: new Date(newStartDate.getTime() + diff) };
    },
  },
};

export function TimeRangeSelector({ value, onChange, onDateChange }: TimeRangeSelectorProps) {
  const [date, setDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const { t } = useTranslation();

  const lang = get_language_setting();
  setDefaultOptions({ locale: lang === 'en' ? enUS : es });

  const yearOptions = generateYearOptions();
  const monthOptions = generateMonthOptions();

  useEffect(() => {
    handleDateChange(date, endDate);
  }, [value]); // Re-calculate date range when value changes

  const handleDateSelect = (newDate: DateRange | Date) => {
    if ('from' in newDate && 'to' in newDate && newDate.from) {
      handleDateChange(newDate.from, newDate.to);
      return;
    }

    handleDateChange(newDate as Date);
  };

  const calendarMode = value === 'custom' ? 'range' : 'single';

  const handleDateChange = (newDate: Date, newEndDate?: Date) => {
    setDate(newDate);
    if (value === 'week' && newEndDate) {
      const weekStart = startOfWeek(newDate);
      const weekEnd = endOfWeek(newEndDate);
      setDate(weekStart);
      setEndDate(weekEnd);
      onDateChange(newDate, newEndDate);
      return;
    }
    if (value === 'custom' && newEndDate) {
      setEndDate(newEndDate);
      onDateChange(newDate, newEndDate);
      return;
    }

    if (value !== 'custom') {
      const { start, end } = timeRangeConfig[value].getRange(newDate);
      onDateChange(start, end);
    }
  };

  const moveDate = (direction: 'forward' | 'backward') => {
    if (value === 'custom') {
      const { start, end } = timeRangeConfig.custom.move(date, endDate, direction);
      handleDateChange(start, end);
    } else {
      const newDate = timeRangeConfig[value].move(date, direction);
      handleDateChange(newDate);
    }
  };

  const formatDate = () => {
    if (value === 'custom') {
      return timeRangeConfig.custom.format(date, endDate);
    }
    return timeRangeConfig[value].format(date);
  };

  const modifiers = {
    weekend: (day: Date) => {
      if (value === 'week') {
        const weekStart = startOfWeek(date);
        const weekEnd = endOfWeek(date);
        return isSameDay(day, weekStart) || isSameDay(day, weekEnd);
      }
      return false;
    },
    week: (day: Date) => {
      if (value === 'week') {
        const weekStart = startOfWeek(date);
        const weekEnd = endOfWeek(date);
        return isWithinInterval(day, { start: weekStart, end: weekEnd });
      }
      return false;
    },
  };

  const modifiersClassNames = {
    week: 'bg-sky-100 hover:bg-sky-500 hover:text-sky-100 text-sky-500',
    weekend: 'bg-[#0ea5e9] hover:bg-sky-100 hover:text-sky-500 text-[#e0f2fe]',
  };

  return (
    <div className="my-4">
      <RadioGroup label={t('time-range-label')} className="mb-8">
        <div className="flex items-center mr-2">
          <RadioGroupOption
            onChange={() => {
              onChange('day');
              handleDateChange(date);
            }}
            label={t('time-range-daily')}
            id="time-daily"
            checked={value === 'day'}
            required
          />
        </div>
        <div className="flex items-center mx-2">
          <RadioGroupOption
            onChange={() => onChange('week')}
            label={t('time-range-weekly')}
            id="time-weekly"
            checked={value === 'week'}
            required
          />
        </div>
        <div className="flex items-center mx-2">
          <RadioGroupOption
            onChange={() => onChange('month')}
            label={t('time-range-monthly')}
            id="time-monthly"
            checked={value === 'month'}
            required
          />
        </div>
        <div className="flex items-center mx-2">
          <RadioGroupOption
            onChange={() => onChange('custom')}
            label={t('time-range-custom')}
            id="time-range"
            checked={value === 'custom'}
            required
          />
        </div>
      </RadioGroup>
      <div className="flex items-center align-middle space-x-4">
        <button type="button" onClick={() => moveDate('backward')} disabled={value === 'custom'}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        {value === 'month' ? (
          <>
            <SelectField
              name="month"
              label={t('time-range-label-month')}
              className="w-32"
              value={(date.getMonth() + 1).toString().padStart(2, '0')}
              onChange={(e) => {
                const newDate = new Date(date);
                newDate.setMonth(parseInt(e.target.value) - 1);
                handleDateChange(newDate);
              }}
              options={monthOptions?.map((month) => ({
                [`${month.value}`]: month.label ?? '',
              }))}
            />
            <SelectField
              className=" w-20"
              name="year"
              label={t('time-range-label-year')}
              value={date.getFullYear().toString()}
              onChange={(e) => {
                const newDate = new Date(date);
                newDate.setFullYear(parseInt(e.target.value));
                handleDateChange(newDate);
              }}
              options={yearOptions.map((year) => year.toString())}
            />
          </>
        ) : (
          <Popover>
            <PopoverButton
              className={clsx(
                'input-field inline-flex items-center justify-start rounded-sm px-3 py-2 ',
                'hover:bg-neutral-100 hover:text-accent-foreground',
                'w-[300px] text-left',
                !date && 'text-gray'
              )}
            >
              <FontAwesomeIcon icon={faCalendar} className="mr-2 h-4 w-4" />
              {formatDate()}
            </PopoverButton>
            <PopoverPanel anchor="bottom" className="flex flex-col shadow-md">
              {calendarMode === 'single' ? (
                <Calendar
                  required
                  mode={'single'}
                  showWeekNumber={value === 'week'}
                  selected={date}
                  onSelect={handleDateSelect}
                  modifiers={modifiers}
                  modifiersClassNames={modifiersClassNames}
                />
              ) : (
                <Calendar
                  required
                  mode="range"
                  selected={{ from: date, to: endDate }}
                  onSelect={handleDateSelect}
                />
              )}
            </PopoverPanel>
          </Popover>
        )}
        <button type="button" onClick={() => moveDate('forward')} disabled={value === 'custom'}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
}
