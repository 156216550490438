import { useCallback, useState } from 'react';
/*
GlobalHeader, a header component that exists at the top of all pages
*/

//internationalization and translation support
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/pro-solid-svg-icons';

import i18n from '@/libs/i18n.js';
import { useCurrentClinic } from '@/hooks/use-current-clinic';
import logo from '@/assets/images/vitazi-logo-transparent.png';

import { LanguageSwitcher } from '@/components/language-switcher';

import { NavigationItems } from './navigation/navigation-items';

export const GlobalHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { clinicName } = useCurrentClinic();

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  return (
    <>
      <div className="global-header">
        <div className="small-8 medium-9 large-10 cell">
          <div className="your-clinic-name-wrapper">
            <div>
              <button
                className="inline-block lg:hidden"
                aria-label="Menu"
                type="button"
                onClick={toggleMenu}
              >
                <FontAwesomeIcon icon={faBars} size="lg" />
              </button>
            </div>
            <div className="inline-flex-full-center">
              <div className="clinic-name-wrapper">
                <div className="your-clinic">{i18n.t('header-your-clinic')}</div>
                <h1 className="clinic-name">{clinicName}</h1>
              </div>
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="lg:hidden absolute bg-white top-0 left-0 right-0 bottom-0 z-50 px-8 md:px-[20%]">
          <div className="flex justify-between items-start py-8">
            <div className="w-32">
              <img className="header-logo" alt="Vitazi.ai Logo" src={logo} />
            </div>
            <button aria-label="Close Menu" type="button" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faX} size="lg" />
            </button>
          </div>
          <div className="h-[80vh]">
            <NavigationItems onClick={toggleMenu} />
          </div>
        </div>
      )}
    </>
  );
};
