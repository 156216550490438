import logo from '@/assets/images/vitazi-logo-transparent.png';

import { NavigationItems } from './navigation-items';

export const SideMenu = () => {
  return (
    <div className="side-menu" data-testid="navigation">
      <div className="logo-wrapper">
        <img className="header-logo" alt="Vitazi.ai Logo" src={logo} />
      </div>
      <NavigationItems />
    </div>
  );
};
