import { errorlog } from '@/libs/error-logs';
import { toast_error } from '@/libs/toast-wrappers';
import {
  ERROR_DASH_FETCH_CASE_COUNT,
  ERROR_DASH_FETCH_IMAGE_COUNT,
  ERROR_DASH_FETCH_PATIENT_COUNT,
  ERROR_DASH_FETCH_USER_COUNT,
  ERROR_DASH_STAT_HISTORY,
} from '@/utils/messages';

import { GET } from './base/index';
import { ENDPOINTS } from './endpoints';

type CountParameters = {
  clinic_id?: string;
};
type CountResponse = {
  count: number;
};

export async function getUsersCount(parameters?: CountParameters) {
  try {
    const response = await GET<CountResponse>(ENDPOINTS.COUNT_USERS, { query: parameters });
    return response.count;
  } catch (error) {
    errorlog(error as Error, 'src/api/counts/getUsersCount', 'Failed to get users count');
    toast_error(ERROR_DASH_FETCH_USER_COUNT);
    return Promise.reject(error);
  }
}

export async function getCasesCount(parameters?: CountParameters) {
  try {
    const response = await GET<CountResponse>(ENDPOINTS.COUNT_CASES, { query: parameters });
    return response.count;
  } catch (error) {
    errorlog(error as Error, 'src/api/counts/getCasesCount', 'Failed to get cases count');
    toast_error(ERROR_DASH_FETCH_CASE_COUNT);
    return Promise.reject(error);
  }
}

export async function getCasesImagesCount(parameters?: CountParameters) {
  try {
    const response = await GET<CountResponse>(ENDPOINTS.COUNT_CASES_IMAGES, { query: parameters });
    return response.count;
  } catch (error) {
    errorlog(
      error as Error,
      'src/api/counts/getCasesImagesCount',
      'Failed to get cases images count'
    );
    toast_error(ERROR_DASH_FETCH_IMAGE_COUNT);
    return Promise.reject(error);
  }
}

export async function getPatientsCount(parameters?: CountParameters) {
  try {
    const response = await GET<CountResponse>(ENDPOINTS.COUNT_PATIENTS, { query: parameters });
    return response.count;
  } catch (error) {
    errorlog(error as Error, 'src/api/counts/getPatientsCount', 'Failed to get patients count');
    toast_error(ERROR_DASH_FETCH_PATIENT_COUNT);
    return Promise.reject(error);
  }
}

type DashboardParameters = {
  clinic_id: string;
  interval: string;
  value: string;
};

export type DashboardData = {
  cases: number;
  date: string;
};

export async function getDashboardStats(parameters: DashboardParameters) {
  try {
    const response = await GET<DashboardData>(ENDPOINTS.STATS, { query: parameters });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/counts/getDashboardStats', 'Failed to get dashboard stats');
    toast_error(ERROR_DASH_STAT_HISTORY);
    return Promise.reject(error);
  }
}

type GetAdminDashboardCases = {
  start_date: string;
  end_date: string;
  // filters
  clinic_id?: string;
  site_id?: string;
};

export async function getAdminDashboardCases(parameters: GetAdminDashboardCases) {
  try {
    const response = await GET<DashboardData[]>(ENDPOINTS.DASHBOARD_CASES, { query: parameters });
    return response;
  } catch (error) {
    errorlog(error as Error, 'src/api/counts/getDashboardCases', 'Failed to get dashboard cases');
    toast_error(ERROR_DASH_STAT_HISTORY);
    return Promise.reject(error);
  }
}
