import { ComponentProps } from 'react';

import clsx from 'clsx';
import { ChevronProps, DayPicker } from 'react-day-picker';
import { es, enUS } from 'react-day-picker/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

import { get_language_setting } from '@/utils/translations';

export type CalendarProps = ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  const lang = get_language_setting();

  const dayPickerClassnames = {
    month_grid: 'w-full border-collapse space-y-1',
    weekdays: 'bg-white border border-white',
    weekday: 'text-gray-500 rounded-md w-10 font-normal text-[0.8rem]',
    weeks: 'border border-transparent [&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-white',
    range_start: 'day-range-start',
    range_end: 'day-range-end',
    selected: 'bg-sky-500 hover:bg-sky-500 text-sky-100',
    today: 'font-bold',
    outside: 'day-outside text-sky-500 aria-selected:bg-sky-100 aria-selected:text-sky-500',
    disabled: 'opacity-20',
    range_middle: 'aria-selected:bg-sky-100 aria-selected:text-sky-500',
    hidden: 'invisible',
    day: clsx(
      'relative rounded hover:bg-neutral-100 p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-blue-500 [&:has([aria-selected].outside)]:bg-blue-500/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
      props.mode === 'range'
        ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
        : '[&:has([aria-selected])]:rounded-md'
    ),
    ...classNames,
  };

  return (
    <DayPicker
      locale={lang === 'en' ? enUS : es}
      showOutsideDays={showOutsideDays}
      className={clsx('p-3 rounded-sm border  bg-white', className)}
      classNames={dayPickerClassnames}
      components={{
        Chevron: ({ orientation }: ChevronProps) => {
          if (orientation === 'left') {
            return (
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={clsx(
                  'h-4 w-4 bg-transparent p-0 opacity-50 hover:opacity-100 border-transparent',
                  className
                )}
              />
            );
          }
          return (
            <FontAwesomeIcon
              icon={faChevronRight}
              className={clsx(
                ' h-4 w-4 bg-transparent p-0 opacity-50 hover:opacity-100 border-transparent',
                className
              )}
            />
          );
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
