import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetAdminDashboardData } from '@/hooks/api/use-get-admin-dashboard-data';

import { FetchStates } from '@/components/fetch-states';
import { TimeRangeSelector } from '@/components/time-range-selector';

import { CaseFiltersProps, Filters } from './filters';
import { CaseChart } from './chart';

export function AdminDashboard() {
  const [time, setTime] = useState<'day' | 'week' | 'month' | 'custom'>('week');
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });
  const [filters, setFilters] = useState<CaseFiltersProps['filters']>({
    clinic: 'not-specified',
    site: 'not-specified',
  });

  const { data: cases, loading } = useGetAdminDashboardData({ dateRange, filters });

  const { t } = useTranslation();

  return (
    <div className="page-content-wrapper paper">
      <header className="page-header">
        <h1 className="page-title">{t('admin-dashboard-title')}</h1>
      </header>

      <div className="page-content">
        <TimeRangeSelector
          value={time}
          onChange={setTime}
          onDateChange={(start, end) => setDateRange({ start, end })}
        />
        <Filters filters={filters} onChange={setFilters} />
        <div className="min-h-[500px] grid">
          <FetchStates loading={loading}>{<CaseChart data={cases} />}</FetchStates>
        </div>
      </div>
    </div>
  );
}
