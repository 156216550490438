import { format } from 'date-fns';

// We have this format of date '2024-03-14T14:57:21:139969' and we want to convert it to a readable format.
export function parseDateString(dateString: string): Date | null {
  if (!dateString) return null;

  // Split the string to get the main part and the milliseconds part
  const parts = dateString.trim().replace(' ', 'T').split(':');
  if (parts.length !== 4) {
    console.error('Invalid date format');
    return null;
  }

  // Rebuild the date string correctly
  const mainPart = parts.slice(0, -1).join(':');
  let milliseconds = parts[parts.length - 1];

  // Ensure milliseconds are exactly three digits
  if (milliseconds.length > 3) {
    milliseconds = milliseconds.substring(0, 3);
  } else if (milliseconds.length < 3) {
    milliseconds = milliseconds.padEnd(3, '0');
  }

  // Create the final date string
  const fixedDateString = `${mainPart}.${milliseconds}`;

  // Create the Date object
  const dateObject = new Date(fixedDateString);

  // Check if the date is valid
  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date');
    return null;
  }

  return dateObject;
}

export const generateYearOptions = (yearsAgo = 6) => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: yearsAgo }, (_, i) => currentYear - i).reverse();
};

export const generateMonthOptions = () => {
  return Array.from({ length: 12 }, (_, i) => ({
    value: String(i + 1).padStart(2, '0'),
    label: format(new Date(2000, i, 1), 'MMMM'),
  }));
};
