import { useNavigationItems } from '@/hooks/use-navigation-items';

import { SideMenuItem } from './side-menu-link';

type NavigationItemsProps = {
  onClick?: () => void;
};

export function NavigationItems({ onClick }: NavigationItemsProps) {
  const { navItems, bottomNavLinks } = useNavigationItems();

  return (
    <nav className="flex flex-col justify-between h-full">
      <div>
        <ul className="p-0 list-none my-8">
          {navItems
            .filter((nav) => !nav.href?.includes('admin'))
            .map((nav_link, index) => (
              <SideMenuItem key={`${nav_link.href}-${index}`} item={nav_link} onClick={onClick} />
            ))}
        </ul>
      </div>
      <ul className="p-0 list-none">
        {bottomNavLinks.map((nav_link, index) => (
          <SideMenuItem key={`${nav_link.href}-${index}`} item={nav_link} onClick={onClick} />
        ))}
      </ul>
    </nav>
  );
}
