import { useTranslation } from 'react-i18next';

import { useGetClinics } from '@/hooks/api/use-get-clinics';
import { useGetSitesByClinic } from '@/hooks/api/use-get-sites-by-clinic';

import { SelectField } from '@/components/form/select-field';

export interface CaseFiltersProps {
  filters: {
    clinic?: string;
    site?: string;
  };
  onChange: (filters: CaseFiltersProps['filters']) => void;
}

export function Filters({ filters, onChange }: CaseFiltersProps) {
  const { clinics, loading } = useGetClinics();
  const { sites, loading: loadingSites } = useGetSitesByClinic({
    clinic_id: filters.clinic !== 'not-specified' ? filters.clinic : undefined,
  });

  const { t } = useTranslation();

  const handleChange = (key: keyof CaseFiltersProps['filters'], value: string | boolean) => {
    onChange({ ...filters, [key]: value });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="space-y-2">
        <SelectField
          disabled={loading}
          name="clinics"
          label={t('filter-clinic')}
          value={filters.clinic}
          onChange={(e) => handleChange('clinic', e.target.value)}
          options={[
            { 'not-specified': t('filter-not-specified') },
            ...(clinics?.map((clinic) => ({
              [`${clinic.clinic_id}`]: clinic.clinic_name ?? '',
            })) ?? []),
          ]}
          defaultLabel="Not Specified"
        />
      </div>
      <div className="space-y-2">
        <SelectField
          name="sites"
          label={t('filter-site')}
          disabled={loadingSites || !sites?.length}
          value={filters.site}
          onChange={(e) => handleChange('site', e.target.value)}
          options={[
            { 'not-specified': t('filter-not-specified') },
            ...(sites?.map((site) => ({
              [`${site.site_id}`]: site.description ?? '',
            })) ?? []),
          ]}
        />
      </div>
    </div>
  );
}
