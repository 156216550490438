import { createRoutesFromElements, createBrowserRouter, Route } from 'react-router-dom';

// components
import Login from '@/components/Login';
import ChangePass from '@/components/ChangePass';
import KitchenSink from '@/components/KitchenSink';
import Dashboard from '@/components/Dashboard';
// import Cases from '@/components/Cases';
import { CasesPage } from '@/components/cases-page';
import Users from '@/components/Users';
import Billing from '@/components/Billing';
import LegalDoc from '@/components/LegalDoc';
import { ProfilePage } from '@/components/profile-page';
import { LogoutPage } from '@/components/logout-page';
import { ClinicsAdminPage } from '@/components/admin/clinics';
import { SimpleError } from '@/components/error-pages/simple-error';
import { ClinicForm } from '@/components/admin/clinics/clicnic-form';
import { SiteForm } from '@/components/admin/clinics/site-form';
// Layouts for each page we want to render
import { EmptyLayoutAuth } from '@/components/auth/empty-layout-auth';
import { MainLayout } from '@/components/main-layout';
import { RouterWrapper } from '@/components/navigation/router-wrapper';

// utils
import { REDIRECT_ROUTES, ROUTES } from './routes';
import { authRouteLoader, protectedLoader, redirectHomeLoader } from './loaders';
import { GradingRoutes } from './grading-routes';
import { AdminDashboard } from '@/components/admin/dashboard';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RouterWrapper />}>
      {/* AUTH ROUTES */}
      <Route path="auth" element={<EmptyLayoutAuth />}>
        <Route path={ROUTES.LOGIN} element={<Login />} loader={authRouteLoader} />
        <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePass />} loader={authRouteLoader} />
        <Route path={ROUTES.NEW_PASSWORD} element={<ChangePass />} loader={authRouteLoader} />
      </Route>
      {/* MAIN ROUTES */}
      <Route path="" Component={MainLayout}>
        <Route index element={<Dashboard />} loader={protectedLoader} />
        <Route path={ROUTES.CASES} element={<CasesPage />} loader={protectedLoader} />
        <Route path={ROUTES.BILLING} element={<Billing />} loader={protectedLoader} />
        {/* GRADING ROUTES */}
        {GradingRoutes}
        <Route path={ROUTES.ADMIN}>
          <Route
            path={ROUTES.ADMIN_DASHBOARD}
            element={<AdminDashboard />}
            loader={protectedLoader}
          />
          <Route
            path={ROUTES.ADMIN_CLINICS}
            element={<ClinicsAdminPage />}
            errorElement={<SimpleError errorMessage="clinics-error" />}
            loader={protectedLoader}
          >
            <Route path={`${ROUTES.ADMIN_ADD_CLINIC}`} element={<ClinicForm />} />
            <Route path={`${ROUTES.ADMIN_EDIT_CLINIC}/:clinicId`} element={<ClinicForm />} />
            <Route path={`${ROUTES.ADMIN_ADD_CLINIC_SITE}`} element={<SiteForm />} />
            <Route path={`${ROUTES.ADMIN_EDIT_CLINIC_SITE}`} element={<SiteForm />} />
          </Route>
        </Route>
        <Route path={ROUTES.USERS} element={<Users />} loader={protectedLoader} />
        <Route path={ROUTES.LEGAL} element={<LegalDoc />} loader={protectedLoader} />
        <Route path={ROUTES.KITCHEN_SINK} element={<KitchenSink />} loader={protectedLoader} />
        <Route path={ROUTES.PROFILE} element={<ProfilePage />} loader={protectedLoader} />
        <Route path={ROUTES.LOGOUT} element={<LogoutPage />} loader={protectedLoader} />
      </Route>
      {/* Redirect Routes */}
      {REDIRECT_ROUTES.map((route) => (
        <Route key={route} path={route} element={<></>} loader={redirectHomeLoader} />
      ))}
    </Route>
  )
);
