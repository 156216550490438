import useSWR from 'swr';

import { ENDPOINTS } from '@/api/endpoints';
import { getAdminDashboardCases } from '@/api/dashboard';

type GetDashboardDataProps = {
  dateRange: { start: Date; end: Date };
  filters: {
    clinic?: string;
    site?: string;
  };
};

export const useGetAdminDashboardData = ({ dateRange, filters }: GetDashboardDataProps) => {
  const { start, end } = dateRange;

  const { data, isLoading, mutate } = useSWR(
    [ENDPOINTS.DASHBOARD_CASES, { ...dateRange, ...filters }],
    () =>
      getAdminDashboardCases({
        clinic_id: filters.clinic,
        site_id: filters.site,
        start_date: start.toISOString(),
        end_date: end.toISOString(),
      }),
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    loading: isLoading,
    refetchCases: mutate,
  };
};
